import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Test Week starts today!`}</strong></p>
    <p>{`30 minutes to establish a max:`}</p>
    <p>{`Power Clean `}<em parentName="p">{`and `}</em>{`Front Squat`}</p>
    <p>{`then,`}</p>
    <p>{`Max Unbroken KBS’s (70/53)`}</p>
    <p>{`then,`}</p>
    <p>{`500M Row for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      